/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configFSN: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30168,
        isGruppe: true,
        enable: true,
        metaTitlePrefix: 'FSN Autohaus',
        metaTitleSeperator: ' ',
        projectName: 'FSN Autohaus',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        erweiterteSchnellsuche: true,
    },
    erweiterteSchnellsuche: {
        ...configDefault.erweiterteSchnellsuche,
        filter: ['typ', 'hersteller', 'modell', 'aktion'],
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            ignoreFirma: [11330],
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'typ', 'hersteller', 'modell', 'aufbau', 'standort', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]sofort', '[LG]motor', 'getriebe', 'aufbau', 'standort', 'preis', 'rate', 'ez', 'typ', 'aus'],
        },
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            ignoreFirma: [11330],
        },
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"sofo-100", "Arial", "Helvetica", sans-serif',
        fontSize: 15,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"]': {
                                textAlign: 'left',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid^="carTile_"], &[data-testid="AusstattungLocationInfo_Card"], &[data-testid="LocationUserCard"]': {
                                textAlign: 'left',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"sofo-300", "Arial", "Helvetica", sans-serif',
                    fontWeight: 400,
                    textAlign: 'left',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"sofo-300", "Arial", "Helvetica", sans-serif',
                    fontWeight: 400,
                    textAlign: 'left',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"sofo-300", "Arial", "Helvetica", sans-serif',
                    fontWeight: 400,
                    textAlign: 'left',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"sofo-300", "Arial", "Helvetica", sans-serif',
                    fontWeight: 400,
                    textAlign: 'left',
                },
            },
        }),
        primaryColor: '#0099da',
    },
};

export default configFSN;
