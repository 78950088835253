/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';
import { breakpoints } from '../../theme/breakpoints';
import { FilterOrder, FilterSort } from '../../_types/Car';

const configAvBeier: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53850,
        enable: true,
        projectName: 'AV Beier',
        searchPath: '/avbeier/fahrzeuge',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 14,
        },
        isLeadSystem: false,
        linkDisclaimer: '/avbeier/datenschutz',
    },
    module: {
        ...configDefault.module,
        fahrzeugsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        defaultSort: { order: FilterOrder.DESC, sort: FilterSort.Verkaufspreis },
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]sofort', '[LG]motor', 'getriebe', 'aufbau', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
    },
    fahrzeugdetail: {
        ...configDefault.fahrzeugdetail,
        orderTabs: ['beschreibung', 'ausstattung', 'standort', 'verbrauchsangaben'],
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Lora", serif',
        fontSize: 14,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: { // fix Car Title + Description overflow showing in Cards
                    [`@media (min-width: ${breakpoints.lg}px)`]: {
                        'h5.MuiTypography-h6.MuiListItemText-secondary[data-testid^="carTile_"]': {
                            height: '4.1em !important',
                        },
                    },
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                        'h5.MuiTypography-h6.MuiListItemText-secondary[data-testid^="carTile_"]': {
                            height: '2.1em !important',
                        },
                    },
                },
            },
            MuiBottomNavigation: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            alignItems: 'center',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'height': 'fit-content',
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                    label: {
                        [getStyleQuery()]: {
                            fontSize: '14px',
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin': {
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'padding': '0 17px',
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                                boxShadow: 'none',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'padding': '0 17px',
                            '&:hover': {
                                color: '<colorPrimary>',
                                borderColor: '<colorPrimary>',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'padding': '0 17px',
                            '&#detailview2_button_exposee': {
                                height: '2rem'
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#2e2e2e',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    padding: 0,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    height: 'fit-content',
                },
            },
        }),
        primaryColor: '#e30613',
        secondaryColor: '#444444',
    },
};

export default configAvBeier;
