/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { FilterOrder, FilterSort, ViewOptionsView } from '../_types/Car';
import { ConfigObject, Lang, TrackingProvider } from './types';

const configDefault: ConfigObject = {
    general: {
        idFirma: undefined,
        acceptMapsExternal: false,
        debug: false,
        enable: false,
        hideVerfugbarLabels: false,
        initialSearchPath: undefined,
        isGruppe: false,
        isLandingpage: false,
        language: Lang.deDE,
        metaTitlePrefix: undefined,
        metaTitleSeperator: ' | ',
        metaTitleSuffix: undefined,
        mockup: false,
        projectName: undefined,
        redirectDomain: undefined,
        searchPath: '/fahrzeugsuche',
        searchPathDark: undefined,
        searchPathSearch: undefined,
        showroooom: false,
        showFinanzierungen: true,
        showLeasing: true,
        showUpeWithTransferInsteadOfVk: false,
        showNettoUpeWithTransferInsteadOfVkn: false,
        useOldRouter: false,
        useCanonicalLinks: false,
        useOldVersion: false,
    },
    contact: {
        blacklistLeadsystem: undefined,
        buttons: {
            offsetX: 2,
            offsetY: 2,
            position: 'right',
        },
        carSearchFilters: ['hersteller', 'modell', 'ezVon', 'motor'],
        catchCustomFields: undefined,
        isLeadSystem: undefined,
        isCatchLeadSystem: undefined,
        linkDisclaimer: undefined,
        phoneRequired: false,
        useTextInsteadOfPrivacyCheckBox: undefined,
    },
    api: {
        autoUncle: {
            departmentIds: undefined,
            id: undefined,
            origin: undefined,
            priceRating: false,
            sourceName: undefined,
            tradeIn: false,
        },
        faaren: {
            apiKey: undefined,
            enabled: false,
        },
        itTectureUcl: {},
        tracking: {
            apiKey: null,
            customScripts: {
                commonSuccess: true,
                formCommonSuccess: undefined,
                mailClick: undefined,
                mapsClick: undefined,
                printClick: undefined,
                phoneClick: undefined,
                shareClick: undefined,
                tradeInClick: undefined,
            },
            enabled: true,
            provider: TrackingProvider.Octo,
        },
        webcalc: {
            enabled: true,
            domain: 'HEY.CAR.ERNST.DEALER',
        },
    },
    module: {
        aktionsslider: false,
        erweiterteSchnellsuche: false,
        fahrzeugliste: true,
        fahrzeugslider: false,
        fahrzeugsuche: true,
        finanzierungsansicht: false,
        schnellsuche: false,
        standorte: false,
    },
    aktionsslider: {
        adjustColNumberByListLength: false,
        defaultFilter: undefined,
        defaultSort: undefined,
        forcedFilter: {
            sort: 'random',
            aktion: 1,
        },
        forcedDefaultFilter: undefined,
        limitCols: 4,
        noAnimations: false,
    },
    cartile: {
        badges: ['aktion'],
        infoGrid: {
            KRAD: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]farbe', 'antrieb'],
            default: ['ez', 'km', '[L]ident', 'engine.leistung', 'engine.motor', 'getriebe', '[L]farbe', 'antrieb', 'verbrauch.combined', 'verbrauch.co2class', 'verbrauch.emission'],
        },
    },
    erweiterteSchnellsuche: {
        adjustColNumberByListLength: false,
        buttonBelowFilters: false,
        defaultFilter: undefined,
        defaultSort: undefined,
        filter: ['hersteller', 'modell', 'ezVon', 'motor'],
        forcedFilter: {
            page: 1,
            pageSize: 15,
        },
        forcedDefaultFilter: undefined,
        limitCols: 4,
        noAnimations: false,
        oneFilterPerRow: false,
    },
    fahrzeugliste: {
        defaultFilter: undefined,
        defaultSort: undefined,
        forcedFilter: {
            page: 1,
            pageSize: 9,
        },
        forcedDefaultFilter: undefined,
        limitCols: 3,
        noAnimations: false,
    },
    fahrzeugslider: {
        adjustColNumberByListLength: false,
        defaultFilter: undefined,
        defaultSort: undefined,
        filter: ['hersteller', 'modell'],
        forcedFilter: {
            page: 1,
            pageSize: 15,
        },
        forcedDefaultFilter: undefined,
        limitCols: 4,
        noAnimations: false,
    },
    fahrzeugsuche: {
        customNameSofort: undefined,
        defaultFilter: undefined,
        defaultSort: undefined,
        detailsSingleRow: false,
        filter: {
            sidebar: ['tags', 'sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]tags', '[SM]hersteller', '[LG]modell', '[LG]typ', 'sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        firstImageIndex: undefined,
        forcedFilter: {
            pageSize: 18,
        },
        forcedDefaultFilter: undefined,
        hideContactPersons: false,
        hideFooterAutohausDigitalLink: false,
        hideFooterVerbrauch: false,
        hideHeading: true,
        hideProbefahrt: false,
        idExposee: 1521,
        limitCols: 3,
        listView: ViewOptionsView.Grid,
        noAnimations: false,
        originalH1: undefined,
        shareButtons: undefined,
        titleWithDesign: false,
        topFilter: false,
        tradeInLink: undefined,
        useEyecatcher: false,
        useLocationMap: false,
    },
    fahrzeugdetail: {
        additionalInfo: [],
        hideVerbrauch: false,
        infoGrid: {
            KRAD: ['ident', 'typ', 'ez', 'km', 'engine.motor', 'getriebe', 'engine.leistung', 'farbe', 'engine.hubraum', 'standort', 'siegel_garantie', 'antrieb'],
            default: ['ident', 'typ', 'ez', 'km', 'engine.motor', 'getriebe', 'engine.leistung', 'farbe', 'tueren', 'sitze', 'siegel', 'antrieb', 'verbrauch.combined', 'verbrauch.emission', 'verbrauch.co2class'],
        },
        orderTabs: [],
        showLocationImage: true,
        similarCarsFilter: {},
        variant: 1,
    },
    finanzierungsansicht: {
        defaultFilter: undefined,
        defaultSort: { order: FilterOrder.ASC, sort: FilterSort.Rate },
        filter: ['monatlicheRateButton', 'sortButton'],
        forcedFilter: {
            fiz: 1,
            page: 1,
            pageSize: 12,
        },
        forcedDefaultFilter: undefined,
        limitCols: 4,
        noAnimations: false,
    },
    install: {
        showMagicIdSetup: false,
    },
    schnellsuche: {
        buttonBelowFilters: false,
        defaultFilter: undefined,
        defaultSort: undefined,
        filter: ['hersteller', 'modell'],
        forcedFilter: {
            onlyFacetten: 1,
        },
        forcedDefaultFilter: undefined,
        limitCols: 4,
        noAnimations: false,
        noBorderPadding: false,
        oneFilterPerRow: false,
    },
    standorte: {
        defaultFilter: undefined,
        defaultSort: undefined,
        forcedFilter: undefined,
        forcedDefaultFilter: undefined,
        limitCols: undefined,
        noAnimations: undefined,
    },
    style: {
        background: undefined,
        borderRadius: 2,
        darkMode: false,
        disableSliderShadow: false,
        fontFamily: '"Open Sans", "Arial", sans-serif',
        fontSize: 14,
        muiComponents: () => ({}),
        muiTypography: () => ({}),
        oldMuiBreakpoints: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1520,
        },
        oldMuiComponents: {},
        oldMuiTypography: {},
        noAnimations: false,
        primaryColor: '#0d1b48',
        scrollOffset: undefined,
        secondaryColor: '#666666',
    },
    domContainerId: undefined,
};

export default configDefault;
