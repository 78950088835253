/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configLutherkirche: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 48123,
        enable: true,
        projectName: 'Autohaus an der Lutherkirche',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
         filter: {
             sidebar: ['tags', 'sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
             top: ['[SM]tags', '[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
         },
     },
    style: {
        ...configDefault.style,
        fontSize: 16,
        fontFamily: 'dmsans-regular',
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"dmsans-bold"',
                },
            },
        }),
        primaryColor: '#ce8946',
        secondaryColor: '#666666',
    },
};

export default configLutherkirche;
